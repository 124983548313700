.FontAwesomeButton {
    border: none;
    color: #000;
    margin: 0.5rem;
    box-sizing: border-box;
    width: 100%;
}

.FontAwesomeButton:hover {
    border: solid 1px #61dafb;
    color: #61dafb;
    border-radius: 0.5rem;
}